<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>统计报表</BreadcrumbItem>
                <BreadcrumbItem>客户数据</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.customerDateRange" placeholder="请选择客户产生周期" separator=" 至 "></DatePicker>
                </FormItem>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.followupDateRange" placeholder="请选择跟进记录周期" separator=" 至 "></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.userId" placeholder="请选择用户" filterable clearable>
                        <Option v-for="item in userArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <deptTreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Select v-model="filter.source"  placeholder="请选择客户来源" filterable clearable label-in-value>
                        <Option v-for="item in sourceArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-cloud-download" @click="handleExport" v-if="permissionButton.export">导出</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table border stripe :columns="columns" :data="dataList" :loading="loading"></Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqGetDictionaryListByCode, reqGetUserList} from "../../../api/system-api";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import deptTreeSelect from "@riophae/vue-treeselect";
import {reqGetDepartmentTreeOpts, reqGetCustomerReport, reqExportCustomerReport} from "../../../api/admin-api";

export default {
    name: 'customerReport',
    components: {deptTreeSelect},
    data() {
        return {
            filter: {
                customerDateRange: null,
                followupDateRange: null,
                userId: null,
                departmentId: null,
                source: null,
            },
            userArray: [],
            departmentOpts: [],
            sourceArray: [],
            loading: false,
            dataList: [],
            permissionButton: {
                export: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '用户',
                    key: 'createByName',
                    width: 120,
                },
                {
                    title: '所属组织',
                    key: 'departmentName',
                    tooltip: 'true',
                    width: 150,
                },
                {
                    title: '客户名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '客户创建时间',
                    key: 'createTime',
                    align: 'center',
                    width: 180,
                    render: (h, params) => {
                        return h('span', this.$moment(params.row.createTime).format("YYYY-MM-DD HH:mm:ss"));
                    }
                },
                {
                    title: '客户来源',
                    key: 'sourceName',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '商机数',
                    key: 'countsOfOpportunity',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '职位数',
                    key: 'countsOfPosition',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '联系人数',
                    key: 'countsOfContact',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '跟进记录数',
                    key: 'countsOfFollowup',
                    align: 'center',
                    width: 130,
                }
            ],
        }
    },
    methods: {
        init() {
            reqGetUserList().then((res) => {
                this.userArray = res.data;
            }).catch(() => {
                this.userArray = [];
            })

            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            });

            reqGetDictionaryListByCode({code:'customer_source'}).then((res)=>{
                this.sourceArray = res.data.data
            }).catch(()=>{
                this.sourceArray = []
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getCustomerReport();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getCustomerReport();
        },
        doSearching() {
            this.pageNum = 1;
            this.getCustomerReport();
        },
        getCustomerReport() {
            this.loading = true;
            this.dataList = [];
            let customerStartDate = ((this.filter.customerDateRange && this.filter.customerDateRange.length > 0 && this.filter.customerDateRange[0]) ? this.$moment(this.filter.customerDateRange[0]).format('YYYY-MM-DD') : null);
            let customerEndDate = ((this.filter.customerDateRange && this.filter.customerDateRange.length > 1 && this.filter.customerDateRange[1]) ? this.$moment(this.filter.customerDateRange[1]).format('YYYY-MM-DD') : null);
            let followupStartDate = ((this.filter.followupDateRange && this.filter.followupDateRange.length > 0 && this.filter.followupDateRange[0]) ? this.$moment(this.filter.followupDateRange[0]).format('YYYY-MM-DD') : null);
            let followupEndDate = ((this.filter.followupDateRange && this.filter.followupDateRange.length > 1 && this.filter.followupDateRange[1]) ? this.$moment(this.filter.followupDateRange[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                customerStartDate: customerStartDate,
                customerEndDate: customerEndDate,
                followupStartDate: followupStartDate,
                followupEndDate: followupEndDate,
            }, this.filter);

            reqGetCustomerReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.customerDateRange= null;
            this.filter.followupDateRange= null;
            this.filter.userId= null;
            this.filter.departmentId= null;
            this.filter.source= null;

            let customerStartDate = ((this.filter.customerDateRange && this.filter.customerDateRange.length > 0 && this.filter.customerDateRange[0]) ? this.$moment(this.filter.customerDateRange[0]).format('YYYY-MM-DD') : null);
            let customerEndDate = ((this.filter.customerDateRange && this.filter.customerDateRange.length > 1 && this.filter.customerDateRange[1]) ? this.$moment(this.filter.customerDateRange[1]).format('YYYY-MM-DD') : null);
            let followupStartDate = ((this.filter.followupDateRange && this.filter.followupDateRange.length > 0 && this.filter.followupDateRange[0]) ? this.$moment(this.filter.followupDateRange[0]).format('YYYY-MM-DD') : null);
            let followupEndDate = ((this.filter.followupDateRange && this.filter.followupDateRange.length > 1 && this.filter.followupDateRange[1]) ? this.$moment(this.filter.followupDateRange[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: 1,
                pageSize: 20,
                customerStartDate: customerStartDate,
                customerEndDate: customerEndDate,
                followupStartDate: followupStartDate,
                followupEndDate: followupEndDate,
            }, this.filter);

            reqGetCustomerReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleExport() {
            this.$Modal.confirm({
                title: '导出客户',
                content: '确定导出客户查询结果？',
                width: '320',
                onOk: () => {
                    let customerStartDate = ((this.filter.customerDateRange && this.filter.customerDateRange.length > 0 && this.filter.customerDateRange[0]) ? this.$moment(this.filter.customerDateRange[0]).format('YYYY-MM-DD') : null);
                    let customerEndDate = ((this.filter.customerDateRange && this.filter.customerDateRange.length > 1 && this.filter.customerDateRange[1]) ? this.$moment(this.filter.customerDateRange[1]).format('YYYY-MM-DD') : null);
                    let followupStartDate = ((this.filter.followupDateRange && this.filter.followupDateRange.length > 0 && this.filter.followupDateRange[0]) ? this.$moment(this.filter.followupDateRange[0]).format('YYYY-MM-DD') : null);
                    let followupEndDate = ((this.filter.followupDateRange && this.filter.followupDateRange.length > 1 && this.filter.followupDateRange[1]) ? this.$moment(this.filter.followupDateRange[1]).format('YYYY-MM-DD') : null);
                    this.filter.customerStartDate = customerStartDate;
                    this.filter.customerEndDate = customerEndDate;
                    this.filter.followupStartDate = followupStartDate;
                    this.filter.followupEndDate = followupEndDate;

                    reqExportCustomerReport(this.filter).then((res)=>{
                        let filename = 'Customer.xlsx';
                        const disposition = res.headers['content-disposition'];
                        if (typeof disposition !== 'undefined') {
                            let deDisposition = decodeURIComponent(disposition);
                            filename = deDisposition.substring(deDisposition.indexOf('filename=') + 9, deDisposition.length);
                        }

                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'}) //type这里表示xlsx类型
                        let downloadElement = document.createElement('a')
                        let href = window.URL.createObjectURL(blob) //创建下载的链接
                        downloadElement.href = href
                        downloadElement.download = filename //下载后文件名
                        document.body.appendChild(downloadElement)
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                    }).catch((err)=>{
                        this.$Message.error('导出失败')
                    })
                },
            })
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.init();
        this.getCustomerReport();
    }
}
</script>
